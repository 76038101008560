import React from 'react';
import '../node_modules/jquery/dist/jquery.min.js';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.scss';
import './assets/sass/style.scss';
import Homepage from './components/Homepage';
import ListeningThankYouSignUp from './components/ThankyouSignUp';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import LegalDisclaimer from './components/LegalDisclaimer';
import CookiePolicy from './components/CookiePolicy';
import Header from './components/Header';
import Footer from './components/Footer';
import ErrorPage from './components/ErrorPage';

function App() {
  return (
    <div>
      <Router>
        <Header />
        <Switch>
          <Route path="/" exact component={Homepage} />
          {/* <Route path="/huddles/:id" exact component={HuddleListeningSignupComponent}/> */}
          <Route
            path="/listening-thank-you"
            exact
            component={ListeningThankYouSignUp}
          />
          {/* <Route path="/thank-you" exact component={ThankYouSignUp}/> */}
          <Route path="/privacy-policy" exact component={Privacy} />
          <Route path="/terms" exact component={Terms} />
          <Route path="/legal-disclaimer" exact component={LegalDisclaimer} />
          <Route path="/cookie-policy" exact component={CookiePolicy} />
          <Route path="/404" exact component={ErrorPage} />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
