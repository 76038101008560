import React from 'react';

function LegalDisclaimerComponent() {
    return (
        <div className="top-padding">
        <section className="page-section clearfix">
            <div className="container-fluid custom-container">
                <div className="row gx-5 align-items-center justify-content-center justify-content-lg-between">
                    <div className="col-sm-8 col-md-5 order-md-2">
                    </div>
                    <div className="col-12 col-lg-7">
                        <h1 className="h1-heading lh-1 mb-5">Legal Disclaimer</h1>
                    </div>
                </div>
                <p><strong>No professional-client relationship</strong></p>
                <p><span>The Biotone website and mobile application (&ldquo;Platform&rdquo;) connects you with professionals (clinical psychologists, counselling psychologists and life coaches).&nbsp;</span></p>
                <p><span>Biotone itself is a technology platform, not a mental health service provider. All mental health advice given to you through the Platform is being provided by qualified professionals. Biotone does not, and is not qualified to, interfere in the treatment plan recommended by the&nbsp; &nbsp; &nbsp; professional.</span></p>
                <p><span>Any interactions and associated issues with the professional is strictly between you and the&nbsp; &nbsp; &nbsp; professional. Biotone will not be liable for any such interactions and associated issues.</span></p>
                <p><strong>Biotone is not a crisis hotline</strong></p>
                <p><span>Biotone is not a crisis hotline, and should not be used in emergency situations.&nbsp;</span></p>
                <p><span>If you are thinking of harming yourself or someone else, please reach out to a crisis hotline such as Aasra 24x7 Helpline: 91-22-27546669 or Vandrevala Foundation Helpline - 1 860 266 2345 (24x7).</span></p>
                <p><span>Note that Biotone is not associated with Aasra Helpline and Vandrevala Foundation Helpline, and does not endorse that they will or can help you.</span></p>
                <p><span>&nbsp;</span><strong>Biotone and professional make no guarantees</strong></p>
                <p><span>Counseling or coaching (whether in-person or online) is a journey, and everyone&rsquo;s route is different. While Biotone and the treating professional strive to see you achieve the results that you desire, there are numerous factors that are not in our control that contribute to the success of it. Biotone and the&nbsp; &nbsp; &nbsp; professional hereby clarify that there is no guarantee that the treatment will yield the desired results.</span></p>
                <p><span>Further, even in instances where teleconsultation is deemed a suitable option by the professional, it may not be the optimum mode of rendering treatment and is accompanied by certain inherent limitations, and neither Biotone nor the professional shall be responsible for any shortcomings as a result.</span></p>
                <p><span>Biotone takes reasonable efforts to ensure that the content and information on the Platform is up-to-date and accurate,&nbsp; but remember that it is not a substitute for professional advice. Do consult a professional to ascertain whether the content is applicable to you.</span></p>
                <p><span>Biotone also disclaims any representation and/or warranty for the security, reliability, quality, timeliness, and performance of the Platform and its features.&nbsp;</span></p>
                <p><strong>Biotone does not dictate session fees</strong></p>
                <p><span>Biotone offers access to professionals who offer sessions at varying price points. The fee that each professional charges per session is determined by them based on various factors (which could include their education, certifications, training, work experience, expertise, locations, etc), and Biotone does not advise them on or interfere with the fee that they decide. The professionals are also at liberty to modify their fee per session as they deem fit. Biotone does not interfere with such modification unless it is brought to our attention that a professional has increased their fee by an unreasonable amount for no valid reason.&nbsp;</span></p>
                <p><strong>Non solicitation</strong></p>
                <p><span>The professionals offering their services through Biotone do not intend to solicit clients for their practice by doing so. All content created by professionals for Biotone are purely for educational and public awareness purposes. </span></p>
            </div>
        </section>
        </div>
    );
}

export default LegalDisclaimerComponent;