import React, {useEffect } from 'react';
import CardDeck from 'react-bootstrap/CardDeck';
import Card from 'react-bootstrap/Card';
function ThankYouSignUp(props) {
    useEffect(()=> {
        document.title = "Thank You | Biotone";
        if (!!props && !!props.location && props.location.state === undefined) {
            window.location.href = '/huddles';
        }
        setTimeout(() => {
            var el2 = document.getElementById("showElement");
            el2.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        }, 200);
    });
    return (
        <div className="top-padding">
        {!!props && !!props.location && !!props.location.state && !!props.location.state.successFlag ?
            <section className="page-section clearfix">
                <div className="container-fluid custom-container" id="showElement">
                    <CardDeck className="mt-0">
                    <Card className="custom-card">
                        <Card.Body>
                            <div className="d-flex align-items-center justify-content-center thank-you-confirmation">
                                <div className="text-center">
                                <h3 className="h1-heading lh-1 text-center">Thank you for signing up for our {!!props && !!props.location && !!props.location.state && !!props.location.state.type && props.location.state.type !== '' ? props.location.state.type : ''}!</h3>
                                <p className="lead-text fw-500">We will confirm your participation with you shortly</p>
                                <div className="text-center">
                                <a href="/" className="btn btn-primary">Home</a>
                                </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    </CardDeck>
                </div>
            </section> : <></>}
        </div>
    );
}

export default ThankYouSignUp;