import React, { useEffect } from 'react';
import LocationImage from './../../assets/images/illutrations/new-images/locationimg.png';
import PhoneImage from './../../assets/images/illutrations/new-images/phoneimg.png';
import MailImage from './../../assets/images/illutrations/new-images/mailimg.png';
import ContactUsFormComponent from './ContactUs';
function ContactUsComponent() {
  useEffect(() => {}, []);
  return (
    <>
      <section className="contact-us-section">
        <div className="container">
          <div className="row gx-5 align-items-center justify-content-center justify-content-lg-between">
            <div className="d-block d-sm-none">
              <h3 className="h1-heading mb-4">Contact us or request a demo</h3>
              <p className="lead-text mb-5">
                We're happy to discuss ways in which BioTone can meet your
                authentication &amp security needs as well as review how you
                believe we could work together. Contact us via the details below
                or submit your request and we'll get back to you within 24-48
                hours.
              </p>
            </div>
            <div className="col-sm-8 col-md-6 order-md-2">
              <ContactUsFormComponent />
            </div>
            <div className="col-12 col-lg-6 col-sm-6">
              <div className="d-none d-sm-block">
                <h3 className="h1-heading mb-4">
                  Contact us or request a demo
                </h3>
                <p className="lead-text mb-5">
                  We're happy to discuss ways in which BioTone can meet your
                  authentication & security needs as well as review how you
                  believe we could work together. Contact us via the details
                  below or submit your request and we'll get back to you within
                  24-48 hours.
                </p>
              </div>
              <div className="row">
                <div className="col-2 my-auto">
                  <div>
                    <img className="img-fluid" src={LocationImage} alt="..." />
                  </div>
                </div>
                <div className="col-10 my-auto">
                  <p className="my-auto">
                    201 East Fifth Street, Suite 1900,
                    <br />
                    Cincinnati, OH 45202
                  </p>
                </div>
              </div>
              <div className="row my-4">
                <div className="col-2 my-auto">
                  <div>
                    <img className="img-fluid" src={PhoneImage} alt="..." />
                  </div>
                </div>
                <div className="col-10 my-auto">
                  <p className="my-auto">+1 (513) 721-3900</p>
                </div>
              </div>
              <div className="row">
                <div className="col-2 my-auto">
                  <div>
                    <img className="img-fluid" src={MailImage} alt="..." />
                  </div>
                </div>
                <div className="col-10 my-auto">
                  <p className="my-auto">ckerns@cwpllc.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactUsComponent;
