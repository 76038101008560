import React, { useState, useRef } from 'react';
import CardDeck from 'react-bootstrap/CardDeck';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import emailjs from '@emailjs/browser';
import 'dotenv/config'

function ContactUsComponent(props) {
  const [formsubmittedflag, setFormFlag] = useState(false);
  const [showThankYouScreen, setThankYouScreen] = useState(false);
  const [validationError, setValidation] = useState('');

  const [mailerState, setMailerState] = useState({
    email: '',
    firstName: '',
    lastName: '',
    companyName: '',
    phone: '',
    description: '',
  });

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    saveWorkshopSignupData();
    emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICEID, process.env.REACT_APP_EMAILJS_TEMPLATEID, form.current, process.env.REACT_APP_EMAILJS_PUBLICKEY)
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      })
      .then(() => {
        setMailerState({
          email: '',
          firstName: '',
          lastName: '',
          companyName: '',
          phone: '',
          description: '',
        });
      });
  };


  const handleStateChange = (e) => {
    console.log(e);
    setMailerState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };


  const saveWorkshopSignupData = async () => {
    if (mailerState.email === '') {
      setValidation('Email address is required.');
      setTimeout(() => {
        setValidation('');
      }, 4000);
      return false;
    }
    if (mailerState.firstName === '') {
      setValidation('First Name is required.');
      setTimeout(() => {
        setValidation('');
      }, 4000);
      return false;
    }
    if (mailerState.lastName === '') {
      setValidation('Last Name is required.');
      setTimeout(() => {
        setValidation('');
      }, 4000);
      return false;
    }
    if (mailerState.companyName === '') {
      setValidation('Company Name is required.');
      setTimeout(() => {
        setValidation('');
      }, 4000);
      return false;
    }
    if (mailerState.phone === '') {
      setValidation('Phone is required.');
      setTimeout(() => {
        setValidation('');
      }, 4000);
      return false;
    }
    if (mailerState.description === '') {
      setValidation('Description is required.');
      setTimeout(() => {
        setValidation('');
      }, 4000);
      return false;
    }
    if (
      mailerState.description !== '' &&
      (mailerState.description.length < 6 || mailerState.description.length > 1000)
    ) {
      setValidation('Description should be between 5 - 1000 characters.');
      setTimeout(() => {
        setValidation('');
      }, 4000);
      return false;
    }
    if (/^[ A-Za-z0-9.'-]*$/.test(mailerState.firstName) === false) {
      setValidation('No special characters are allowed.');
      setTimeout(() => {
        setValidation('');
      }, 4000);
      return false;
    }
    if (/^[ A-Za-z0-9.'-]*$/.test(mailerState.lastName) === false) {
      setValidation('No special characters are allowed.');
      setTimeout(() => {
        setValidation('');
      }, 4000);
      return false;
    }
    if (/^[ A-Za-z0-9.'-]*$/.test(mailerState.companyName) === false) {
      setValidation('No special characters are allowed.');
      setTimeout(() => {
        setValidation('');
      }, 4000);
      return false;
    }
    if (
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mailerState.email) ===
      false
    ) {
      setValidation('Email address is not correct.');
      setTimeout(() => {
        setValidation('');
      }, 4000);
      return false;
    }
    if (/^[0]?[6789]\d{9}$/.test(mailerState.phone) === false) {
      setValidation('Phone numbers is not correct.');
      setTimeout(() => {
        setValidation('');
      }, 4000);
      return false;
    }
    if (
      mailerState.email !== '' &&
      mailerState.firstName !== '' &&
      mailerState.lastName !== '' &&
      mailerState.companyName !== '' &&
      mailerState.phone !== '' &&
      mailerState.description !== ''
    ) {
      await setFormFlag(true);
      setTimeout(() => {
        setThankYouScreen(true);
      }, 1000);
    }
  };
  return (
    <>
      <section className="contact-us-card">
        <CardDeck className="mt-0">
          <Card>
            <Card.Body>
              <div>
                {!formsubmittedflag ? (
                  <form ref={form} onSubmit={sendEmail} className="custom-form">
                    <div className="row gx-5 mb-4">
                      <div className="col-md-6 px-md-2 mob-mb-4">
                        <div className="form-group">
                          <input
                            placeholder="First Name*"
                            disabled={formsubmittedflag}
                            className="form-control"
                            name="firstName"
                            type="text"
                            value={mailerState.firstName}
                            onChange={handleStateChange}
                            required
                          />
                          {!!validationError &&
                            validationError !== '' &&
                            (validationError === 'Name is required.' ||
                              validationError ===
                              'No special characters are allowed.') ? (
                            <p className="error-msg mb-0">{validationError}</p>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6 px-md-2">
                        <div className="form-group">
                          <input
                            placeholder="Last Name*"
                            disabled={formsubmittedflag}
                            className="form-control"
                            name="lastName"
                            type="text"
                            value={mailerState.lastName}
                            onChange={handleStateChange}
                            required
                          />
                          {!!validationError &&
                            validationError !== '' &&
                            (validationError === 'Name is required.' ||
                              validationError ===
                              'No special characters are allowed.') ? (
                            <p className="error-msg mb-0">{validationError}</p>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row gx-5 mb-4">
                      <div className="col-md-6 px-md-2 mob-mb-4">
                        <div className="form-group">
                          <input
                            placeholder="Your Email address*"
                            disabled={formsubmittedflag}
                            className="form-control"
                            name="email"
                            type="email"
                            value={mailerState.email}
                            onChange={handleStateChange}
                            required
                          />
                          {!!validationError &&
                            validationError !== '' &&
                            (validationError === 'Email is required.' ||
                              validationError ===
                              'Email address is not correct.') ? (
                            <p className="error-msg mb-0">{validationError}</p>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 px-md-2">
                        <div className="form-group">
                          <input
                            placeholder="Phone Number*"
                            disabled={formsubmittedflag}
                            className="form-control"
                            name="phone"
                            type="number"
                            value={mailerState.phone}
                            onChange={handleStateChange}
                            required
                          />
                          {!!validationError &&
                            validationError !== '' &&
                            (validationError === 'Phone is required.' ||
                              validationError ===
                              'Phone numbers is not correct.') ? (
                            <p className="error-msg mb-0">{validationError}</p>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row gx-5 mb-4">
                      <div className="col-md-12 px-md-2">
                        <div className="form-group">
                          <input
                            placeholder="Company Name*"
                            disabled={formsubmittedflag}
                            className="form-control"
                            name="companyName"
                            type="text"
                            value={mailerState.companyName}
                            onChange={handleStateChange}
                            required
                          />
                          {!!validationError &&
                            validationError !== '' &&
                            (validationError === 'Name is required.' ||
                              validationError ===
                              'No special characters are allowed.') ? (
                            <p className="error-msg mb-0">{validationError}</p>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row gx-5 mb-4">
                      <div className="col-md-12 px-md-2">
                        <div className="form-group">
                          <textarea
                            placeholder="Message*"
                            maxLength="1000"
                            minLength="5"
                            disabled={formsubmittedflag}
                            name="description"
                            className="form-control"
                            cols="30"
                            rows="5"
                            value={mailerState.description}
                            onChange={handleStateChange}
                            required
                          />
                          {!!validationError &&
                            validationError !== '' &&
                            (validationError === 'Description is required.' ||
                              validationError ===
                              'Description should be between 5 - 1000 characters.') ? (
                            <p className="error-msg mb-0">{validationError}</p>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row gx-5 mb-4">
                      <div className="col-md-12 px-md-2">
                        <button
                          className="btn btn-primary"
                          type="submit"
                          disabled={formsubmittedflag}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                ) : (
                  <>
                    {showThankYouScreen ? (
                      <div className="d-flex align-items-center justify-content-center thank-you-confirmation pt-5">
                        <div className="text-center">
                          <h3 className="h1-heading lh-1 text-center">
                            Thank you for contacting us.
                          </h3>
                          <p className="lead-text fw-500">
                            We will connect with you shortly
                          </p>
                          <div className="text-center">
                            <a href="/" className="btn btn-primary">
                              Home
                            </a>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="pt-md-5">
                        <div className="d-flex align-items-center justify-content-center">
                          <Spinner animation="border" role="status"></Spinner>
                        </div>
                        <p className="lead-text fw-500 text-center font-italic mt-4">
                          Please wait while your form is being submitted...
                        </p>
                      </div>
                    )}
                  </>
                )}
              </div>
            </Card.Body>
          </Card>
        </CardDeck>
      </section>
    </>
  );
}

export default ContactUsComponent;
