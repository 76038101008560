import React ,{useEffect} from 'react';
import MetaTags from 'react-meta-tags';
import SmallCTAComponent from './shared-components/TextCTA';
// import VideoCornerNew from './shared-components/VideoCornerNew';
import HomepageImg2 from './../assets/images/illutrations/new-images/next-level.svg';
import HomepageImg2Mob from './../assets/images/illutrations/mobile/Auth.svg';
import HomepageImg4 from './../assets/images/illutrations/new-images/whyneed.svg';
import HomepageImg4Mob from './../assets/images/illutrations/mobile/whybiotone.svg';
import HomepageImg5 from './../assets/images/illutrations/new-images/biometrics.svg';
import HomepageImg5Mob from './../assets/images/illutrations/mobile/Biometrics_is_key.svg';
import HomepageImg6 from './../assets/images/illutrations/new-images/GirlMob.svg';
import ContactUsComponent from './shared-components/ContactSection';
// import CardSlider from './shared-components/CardSlider';

function NewHomepageComponent() {
    useEffect(()=> {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    });
    return (
    <div>
                <MetaTags>
                    <title>Biotone</title>
                    <link rel="canonical" href="https://www.biotone.com/" />
                    <meta id="meta-description" name="description" content="We strive to make minds lighter and hearts brighter, helping people bring back their balance, move forward with confidence and recognize their true potential." />
                    <meta name="keywords" content="depression, procrastination, anxiety, lgbtq, mood swings, overthinking, moody, sexual abuse, social anxiety, strengths, negative thoughts, work stress, relationship problems, anxiety attack, stress relief, depression, treatment, anxiety treatment, stress management, stressed out" />
                    <meta property="og:site_name" content="biotone" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Biotone" />
                    <meta property="og:description" content="We strive to make minds lighter and hearts brighter, helping people bring back their balance, move forward with confidence and recognize their true potential." />
                    <meta property="og:url" content="https://www.biotone.com/" />
                    <meta name="og:image" content="%PUBLIC_URL%/logo192.png"/>
                    <meta name="twitter:site" content="biotone"></meta>
                    <meta name="twitter:card" content="summary"></meta>
                    <meta name="twitter:title" content="Biotone"></meta>
                    <meta name="twitter:description" content="We strive to make minds lighter and hearts brighter, helping people bring back their balance, move forward with confidence and recognize their true potential."></meta>
                    <meta name="twitter:image" content="%PUBLIC_URL%/logo192.png"></meta>
                </MetaTags>
                <header className="masthead">
                    <div className="container">
                    <div className="masthead-content">
                    <div className="row gx-5 align-items-center justify-content-center justify-content-lg-between">
                            {/* <div className="col-sm-8 col-md-3 order-md-2"></div> */}
                            <div className="col-12 col-lg-12 col-sm-12">
                                <h1 className="masthead-heading">Real Security at the Right Time</h1>
                                <div className="mob-text-center request-btn">
                                    <a href="/" target="_blank" rel="noreferrer" className="btn btn-primary">Request a demo</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </header>
                <section className="new-page-section clearfix">
                    <div className="container">
                        <div className="row gx-5 align-items-center justify-content-center justify-content-lg-between">
                            <div className="col-12 col-lg-6 col-sm-6">
                                <h3 className="h1-border-heading">What is BioTone?</h3>
                            </div>
                            <div className="col-sm-8 col-md-6 order-md-2">
                                <p className="lead-text-xl">BioTone is an AI-Powered Security solution that combines evolving personal data with multiple biometrics for proper identity verification.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <SmallCTAComponent/>
                <section className="new-page-section clearfix" id="section1">
                    <div className="container">
                        <div className="row gx-5 align-items-center justify-content-center justify-content-lg-between">
                            <div className="col-sm-8 col-md-6">
                                <div className="text-center home-img-1">
                                    <img className="img-fluid d-none d-sm-block" src={HomepageImg4} alt="..." />
                                    <img className="img-fluid d-block d-sm-none m-auto" src={HomepageImg4Mob} alt="..." />
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 col-sm-6 order-md-2 mob-top-padding-20">
                                <h3 className="h1-heading">Why You Need Biotone?</h3>
                                <p className="lead-text">Today's tools won't solve our complex risk landscape as our lives become more digital by the second. Nearly all of our daily activities are completed on a cell phone, tablet, or IoT device. Records for finances, health, personal information, and numerous other points of data are being tracked and stored within databases. As we continue to depend heavier on this Digitalization, there is a growing demand for enhanced security to ensure that this data remains in the right hands at all times.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="new-page-section clearfix">
                    <div className="container">
                    <div className="card border-light reports-card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4 text-center">
                                    <p className="colored-text">11,417,410,545+</p>
                                    <p className="mini-text">U.S. accounts exposed</p>
                                </div>
                                <div className="col-md-4 text-center mob-padding-reports">
                                    <p className="colored-text">270,000+</p>
                                    <p className="mini-text">Credit card fraud cases</p>
                                </div>
                                <div className="col-md-4 text-center">
                                    <p className="colored-text">650,572+</p>
                                    <p className="mini-text">Instances of identity theft</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </section>
                <section className="new-page-section clearfix mob-pb-0">
                    <div className="container">
                    <h3 className="h1-heading d-block d-sm-none mob-text-center">Next Level Authentication</h3>
                        <div className="row gx-5 align-items-center justify-content-center justify-content-lg-between">
                            <div className="col-sm-8 col-md-6">
                                <div className="text-center home-img-1">
                                    <img className="img-fluid d-none d-sm-block" src={HomepageImg2} alt="..." />
                                    <img className="img-fluid d-block d-sm-none m-auto" src={HomepageImg2Mob} alt="..." />
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 col-sm-6 order-md-2 mob-top-padding-20">
                                <h3 className="h1-heading d-none d-sm-block">Next Level Authentication</h3>
                                <p className="lead-text">By using up to 12 factors, BioTone Authentication ensures the highest level of security.</p>
                                <ul className="mb-5 no_bullet">
                                    <li>Access by at least 2 elements including a biometric minimum.</li>
                                    <li>The more factors captured the higher the entropy for the user's protection.</li>
                                    <li>All data can even be encrypted with added measures based on BioTone's elemental factors.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="black_masthead" id="section2">
                    <div className="container">
                    <div className="masthead-content">
                    <div className="d-block d-sm-none">
                        <img className="img-fluid" src={HomepageImg6} alt="..." />
                    </div>
                        <div className="row gx-5 align-items-center justify-content-center justify-content-lg-between">
                            <div className="col-12 col-lg-6 col-sm-6 mb-md-5 mb-3">
                                <h3 className="h1-heading">How BioTone Works?</h3>
                                <p className="lead-text">BioTone authenticates an individual by their unique voice print, captures user specified factors that further encode into an image, and then creates cryptographic keys in order to:</p>
                                <p className="lead-text">👉  Store data</p>
                                <p className="lead-text">👉  Share data</p>
                                <p className="lead-text">👉  Grant Access</p>
                                <p className="lead-text">👉  Authenticate</p>
                            </div>
                            <div className="col-sm-8 col-md-6"></div>
                        </div>
                        {/* <div className="row gx-5 align-items-center justify-content-center justify-content-lg-between mt-md-5 mt-3">
                            <div className="col-12 col-lg-7 col-sm-6 mob-top-padding-20">
                                <h3 className="h1-heading mb-3">The benefits of choosing BioTone</h3>
                                <p className="lead-text">By using upto 12 factors for authentication, BioTone ensures the highest level of security. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                            </div>
                            <div className="col-sm-8 col-md-5"></div>
                        </div> */}
                    </div>
                    {/* <CardSlider/> */}
                    </div>
                </section>
                <section className="biometric_section clearfix" id="section3">
                    <div className="container">
                        <div className="row gx-5 align-items-center justify-content-center justify-content-lg-between">
                            <div className="col-sm-8 col-md-6">
                                <div className="text-center home-img-1">
                                    <img className="img-fluid d-none d-sm-block" src={HomepageImg5} alt="..." />
                                    <img className="img-fluid d-block d-sm-none m-auto" src={HomepageImg5Mob} alt="..." />
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 col-sm-6 order-md-2 mob-top-padding-20">
                                <h3 className="h1-heading">Biometrics are Key</h3>
                                <p className="lead-text">BioTone is not just what you have, or know, but what you are. <span className="green-txt">By pairing your evolving personal data and selected elemental factors with various biometrics, You are your password.</span> We require a bare minimum of a voice print for authentication because even though there are over 7 billion voices in the world, no two are the exact same.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <div id="section4">
                <ContactUsComponent />
                </div>
    </div>
    );
}

export default NewHomepageComponent;