import React from 'react';

function TermsComponent() {
    return (
        <div className="top-padding">
        <section className="page-section clearfix privacy-page">
            <div className="container-fluid custom-container">
                <p>Error Page</p>
            </div>
        </section>
        </div>
    );
}

export default TermsComponent;