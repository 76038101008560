import React from 'react';
import MetaTags from 'react-meta-tags';

function PrivacyComponent() {
    return (
        <div className="top-padding">
        <MetaTags>
            <title>Privacy | Biotone</title>
            <link rel="canonical" href="https://www.biotone.com/" />
            <meta id="meta-description" name="description" content="We strive to make minds lighter and hearts brighter, helping people bring back their balance, move forward with confidence and recognize their true potential." />
            <meta name="keywords" content="depression, procrastination, anxiety, lgbtq, mood swings, overthinking, moody, sexual abuse, social anxiety, strengths, negative thoughts, work stress, relationship problems, anxiety attack, stress relief, depression, treatment, anxiety treatment, stress management, stressed out privacy policies privacy policy" />
            <meta property="og:site_name" content="biotone" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Privacy | Biotone" />
            <meta property="og:description" content="We strive to make minds lighter and hearts brighter, helping people bring back their balance, move forward with confidence and recognize their true potential." />
            <meta property="og:url" content="https://www.biotone.com/" />
            <meta name="og:image" content="%PUBLIC_URL%/logo192.png"/>
            <meta name="twitter:site" content="biotone"></meta>
            <meta name="twitter:card" content="summary"></meta>
            <meta name="twitter:title" content="Privacy | Biotone"></meta>
            <meta name="twitter:description" content="We strive to make minds lighter and hearts brighter, helping people bring back their balance, move forward with confidence and recognize their true potential."></meta>
            <meta name="twitter:image" content="%PUBLIC_URL%/logo192.png"></meta>
        </MetaTags>         
        <section className="page-section clearfix privacy-page">
            <div className="container-fluid custom-container">
                <div className="row gx-5 align-items-center justify-content-center justify-content-lg-between">
                    <div className="col-sm-8 col-md-5 order-md-2">
                    </div>
                    <div className="col-12 col-lg-7">
                        <h1 className="h1-heading lh-1 mb-5">Privacy Policy</h1>
                    </div>
                </div>
                <h3 className="h3-heading lh-1 my-4">PRIVACY POLICY FOR CLIENTS</h3>
                <p><span>Biotone Private Limited (&ldquo;Biotone&rdquo;) cares about your privacy. We only collect information about you that is necessary for us to be able to serve you, and we strive to take reasonable care and protection of the information we receive from you.&nbsp;</span></p>
                <p><span>This Privacy Policy (&ldquo;Privacy Policy&rdquo;) applies to the collection, storage, processing, disclosure and transfer of your Personal Information (defined below) when you use our website or mobile application (collectively, &ldquo;Platform&rdquo;) or avail any services that we offer.&nbsp;</span></p>
                <p><span>This Privacy Policy is a legally binding contract, so we recommend that you read it carefully. Your consent is paramount, and in the event that you are not agreeable to the terms mentioned below, you are free to withhold consent, but please note that you will not be able to use the Platform or avail of our services in that case. You also always have the option of withdrawing your consent at a later stage by writing to us. By using the Platform, you acknowledge that you have read and given your unconditional consent to the terms laid out in this Privacy Policy.&nbsp;</span></p>
                <p><span>The terms &lsquo;you&rsquo; or &lsquo;your&rsquo; refers to anyone who registers on our Platform to avail of mental health or coaching services, and the terms &lsquo;We&rsquo;, &lsquo;Us' and &lsquo;Our&rsquo; refer to Biotone. &lsquo;Professional&rsquo; refers to the clinical psychologists, counselling psychologists, life coaches, performance coaches and leadership coaches who provide the services to you through Biotone.</span></p>
                <p><strong>INFORMATION THAT WE COLLECT</strong></p>
                <p><span>In order to set up your account, get in touch with you, match you with suitable professionals, provide a smooth experience, and comply with the law, we collect information about you. While some of the information that we collect is personally identifiable, we also collect non-personally identifiable information. Following are the types of information that we collect:</span></p>
                <ul>
                <li><span>Your name;</span></li>
                <li><span>Your location;</span></li>
                <li><span>Your contact information;</span></li>
                <li><span>Name and phone number of an emergency contact (you must make sure that they consent to you sharing their information with us);</span></li>
                <li><span>Your gender;</span></li>
                <li><span>Date of birth;</span></li>
                <li><span>Mental health history, status and progress;</span></li>
                <li><span>Journal entries;</span></li>
                <li><span>Financial information that you provide to us when making payments;</span></li>
                <li><span>Account information;</span></li>
                <li><span>Records of interactions with Biotone representatives;</span></li>
                <li><span>Logs of sessions with professionals;</span></li>
                <li><span>Session notes prepared by the professional;</span></li>
                <li><span>Your usage details such as time, frequency, duration and pattern of use, features used and the amount of storage used;</span></li>
                <li><span>Internet Protocol address, browser type, browser language, referring URL, files accessed, errors generated, time zone, operating system and other visitor details collected in Our log files;</span></li>
                <li><span>Any other information that you willingly share with Us.&nbsp;</span></li>
                </ul>
                <p><strong>HOW WE COLLECT INFORMATION&nbsp;</strong></p>
                <p><span>The methods by which we collect your information include but are not limited to the following:</span></p>
                <ol>
                <li><span>When you register on our Platform;</span></li>
                <li><span>When you provide your information to us;</span></li>
                <li><span>Your professional will be required to share some (non-confidential) information with us. In extraordinary circumstances (like when you indicate that you are at risk of harming yourself or someone else, or when you admit that you have harmed someone in the past), your professional may breach confidentiality and share certain information with us or relevant authorities;</span></li>
                <li><span>When You use the features on Our Platform;</span></li>
                <li><span>From third-parties Platform service providers;</span></li>
                <li><span>By the use of cookies (discussed below and in our Cookie Policy [</span><span>hyperlink to Cookie Policy</span><span>]).</span></li>
                </ol>
                <p><strong>USE OF COOKIES</strong></p>
                <p><span>Cookies are small files placed on your device or browser that the Platform uses to identify you and to store information about you. We use &lsquo;cookies&rsquo; to provide a smoother and more personalized experience. These include session cookies, secure cookies, and persistent cookies. You may erase or choose to block these cookies from your computer, but doing so may affect your experience with the Platform. You can configure your computer's browser to alert you when we attempt to send you a cookie with an option to accept or refuse the cookie. If you have turned cookies off, it may limit the functionalities of the Platform. You may also choose to clear cookies from your browser, which&nbsp; would affect your subsequent sessions on the Platform.&nbsp;</span></p>
                <p><strong>PURPOSES FOR WHICH WE COLLECT DATA</strong></p>
                <p><span>We collect information about you and data generated by you as you use the Platform for the following purposes:</span></p>
                <ul>
                <li><span>To create your Biotone account;</span></li>
                <li><span>To verify your identity;</span></li>
                <li><span>To get in touch with you for appointments, technical issues, payment-related issues, etc;</span></li>
                <li><span>To match you with appropriate professionals;</span></li>
                <li><span>To personalize the Platform experience for you;</span></li>
                <li><span>To facilitate payments to professionals;</span></li>
                <li><span>To provide a smooth experience to you;</span></li>
                <li><span>To fulfill our legal obligations;</span></li>
                <li><span>To respond to law enforcement requests, when required by applicable law, court order, or governmental regulations;</span></li>
                <li><span>To transfer information about you if we are acquired or merged with another company;</span></li>
                <li><span>To investigate, prevent, or take action regarding illegal activities, suspected fraud, violation of any contractual obligations, or as otherwise required by law;</span></li>
                <li><span>To aggregate Personal Information for research, statistical analysis and business intelligence purposes, and to sell or otherwise transfer such research, statistical or intelligence data in an aggregated or non-personally identifiable form to third parties and affiliates.</span></li>
                </ul>
                <p><strong>SECURITY</strong></p>
                <p><span>The security of your data is important to us. We have adopted reasonable security practices and procedures including role-based access, password protection, encryption etc. to ensure that the data collected is secure. You agree that such measures are secured and adequate. We restrict access to your data to persons who need access to such data in relation to the purposes as specified above in this Policy, and provided that such entities agree to abide by this Privacy Policy.</span></p>
                <p><strong>SHARING AND TRANSFER OF YOUR INFORMATION</strong></p>
                <p><span>Biotone will never sell your data. However, we may share or transfer some or all of your information with other entities, including but not limited to our affiliates, agents, third-party service providers, partners, banks, financial institutions to fulfill the purposes specified under this Policy, or as required under applicable law. You hereby authorise us to share and transfer your data. You acknowledge that some countries where we may transfer your information may not have data protection laws which are as stringent as the laws of your own country. You acknowledge that it is adequate that when Biotone transfers data to any other entity within or outside Your country of residence, Biotone will place contractual obligations on the transferee which will oblige the transferee to adhere to the provisions of this Privacy Policy.</span></p>
                <p><strong>STORAGE OF YOUR INFORMATION</strong></p>
                <p><span>In furtherance of our obligations under the law, Biotone will continue to store your data on its servers for a minimum period of three (3) years after you last used your account.&nbsp;</span></p>
                <p><strong>CHANGES TO PRIVACY POLICY</strong></p>
                <p><span>We may, at any time, make changes to this Privacy Policy with or without advance notice. In the event of a substantial change, we will notify you over email. However, we recommend that you check this page regularly. There is a tab at the end of this page which specifies when the Policy was last updated. If you object to any of the changes, please cease use of the Biotone Platform immediately, and write to our Grievance Officer to inform them of your concerns.&nbsp;&nbsp;</span></p>
                <p><strong>COMPLIANCE WITH INDIAN LAW</strong></p>
                <p><span>This Privacy Policy is in compliance with the laws of India. You are not allowed to use the services or the Platform, if any of the terms are not in accordance with the applicable laws of your country.&nbsp;</span></p>
                <p><strong>GRIEVANCE OFFICER</strong></p>
                <p><span>We have appointed a Grievance Officer to address any concerns or grievances that you may have regarding the processing of your personal information. If you have any such grievances, please write to our Grievance Officer, Pallavi Surana at </span><a href="mailto:talktous@biotone.com"><span>talktous@biotone.com</span></a><span>, and our officer will attempt to resolve Your issues in a timely manner.&nbsp;</span></p>
                <p>&nbsp;</p>
                <p><strong>DATE OF LAST REVISION: </strong><span>July 19, 2021</span></p>
                <h3 className="h3-heading lh-1 my-4">PRIVACY POLICY FOR PROFESSIONALS</h3>
                <p><span>Biotone Private Limited (&ldquo;Biotone&rdquo;) cares about your privacy. We only collect information about you that is necessary for us to be able to serve You and the Clients, and we strive to take reasonable care and protection of the information we receive from you.&nbsp;</span></p>
                <p><span>This Privacy Policy (&ldquo;Privacy Policy&rdquo;) applies to the collection, storage, processing, disclosure and transfer of your Personal Information (defined below) when You use our website or mobile application (collectively, &ldquo;Platform&rdquo;) or avail any services that we offer.&nbsp;</span></p>
                <p><span>This Privacy Policy is a legally binding contract, so we recommend that you read it carefully. Your consent is paramount, and in the event that you are not agreeable to the terms mentioned below, you are free to withhold consent, but please note that you will not be able to use the Platform or avail of our services in that case. You also always have the option of withdrawing your consent at a later stage by writing to us.</span></p>
                <p><span>The terms &lsquo;you&rsquo; or &lsquo;your&rsquo; refers to professionals offering services through the Biotone Platform, and the terms &lsquo;We&rsquo;, &lsquo;Us' and &lsquo;Our&rsquo; refer to Biotone. &lsquo;Client&rsquo; refers to the Platform users who avail of the services offered by you through Biotone.</span></p>
                <p><strong>INFORMATION THAT WE COLLECT</strong></p>
                <p><span>In order to set up your account and public profile, get in touch with you, match you with suitable Clients, provide a smooth experience, and comply with the law, we collect information about you. While some of the information that we collect is personally identifiable, we also collect non-personally identifiable information.</span></p>
                <ul>
                <li><span>Your name;</span></li>
                <li><span>Your residential address and/or clinic address;</span></li>
                <li><span>Your educational qualifications, including scanned copies of your degrees;</span></li>
                <li><span>Your licensing or accreditation information, if applicable;</span></li>
                <li><span>Identification proof (passport/driver&rsquo;s license/Aadhar card);</span></li>
                <li><span>Your photograph;</span></li>
                <li><span>Financial information that you provide to us for processing of payments;</span></li>
                <li><span>Login ID;</span></li>
                <li><span>Records of interactions with Biotone representatives;</span></li>
                <li><span>Session logs such as duration of session, whether the Client and/or you join the session, whether a session has been rescheduled or cancelled, etc;</span></li>
                <li><span>Your usage details such as time, frequency, duration and pattern of use, features used and the amount of storage used;</span></li>
                <li><span>Internet Protocol address, browser type, browser language, referring URL, files accessed, errors generated, time zone, operating system and other visitor details collected in Our log files;</span></li>
                <li><span>Any other information that you willingly share with Us.&nbsp;</span></li>
                </ul>
                <p><strong>HOW WE COLLECT INFORMATION&nbsp;</strong></p>
                <p><span>The methods by which we collect your information include but are not limited to the following:</span></p>
                <ol>
                <li><span>When You register on Our Platform;</span></li>
                <li><span>When You provide Your Personal Information to Us;</span></li>
                <li><span>During the course of Services provided to You by Us;</span></li>
                <li><span>When You use the features on Our Platform;</span></li>
                <li><span>Feedback and evaluations received from Clients;</span></li>
                <li><span>By the use of cookies (discussed below and in our Cookie Policy [</span><span>hyperlink to Cookie Policy</span><span>]).</span></li>
                </ol>
                <p><strong>USE OF COOKIES</strong></p>
                <p><span>Cookies are small files placed on your device or browser that the Platform uses to identify you and to store information about you. We use cookies &lsquo;to provide a smoother and more personalized experience. These include session cookies, secure cookies, and persistent cookies. You may erase or choose to block these cookies from your computer, but doing so may affect your experience with the Platform. You can configure your computer's browser to alert you when we attempt to send you a cookie with an option to accept or refuse the cookie. If you have turned cookies off, it may limit the functionalities of the Platform. You may also choose to clear cookies from your browser, which&nbsp; would affect your subsequent sessions on the Platform.</span></p>
                <p><strong>PURPOSES FOR WHICH WE COLLECT DATA</strong></p>
                <p><span>We collect information about you and data generated by you as you use the Platform for the following purposes:</span></p>
                <ul>
                <li><span>To onboard you and create your profile;</span></li>
                <li><span>To verify your identity and credentials;</span></li>
                <li><span>To get in touch with you for appointments, technical issues, payment-related issues, etc;</span></li>
                <li><span>To match you with appropriate Clients;</span></li>
                <li><span>To enable you to conduct sessions with the Client;</span></li>
                <li><span>To raise invoices to the Clients and enable you to raise invoices to Biotone;</span></li>
                <li><span>To facilitate payments to you;</span></li>
                <li><span>To personalize the Platform experience for you;</span></li>
                <li><span>To provide a smooth experience to you and the Client;</span></li>
                <li><span>To fulfill our legal obligations;</span></li>
                <li><span>To respond to law enforcement requests, when required by applicable law, court order, or governmental regulations;</span></li>
                <li><span>To transfer information about you if we are acquired or merged with another company;</span></li>
                <li><span>To investigate, prevent, or take action regarding illegal activities, suspected fraud, violation of any contractual obligations, or as otherwise required by law;</span></li>
                <li><span>To aggregate Personal Information for research, statistical analysis and business intelligence purposes, and to sell or otherwise transfer such research, statistical or intelligence data in an aggregated or non-personally identifiable form to third parties and affiliates.</span></li>
                </ul>
                <p><strong>SECURITY</strong></p>
                <p><span>The security of your data is important to us. We have adopted reasonable security practices and procedures including role-based access, password protection, encryption etc. to ensure that the data collected is secure. You agree that such measures are secure and adequate. We restrict access to your data to persons who need access to such data in relation to the purposes as specified above in this Policy, and provided that such entities agree to abide by this Privacy Policy.</span></p>
                <p><strong>SHARING AND TRANSFER OF YOUR INFORMATION</strong></p>
                <p><span>Biotone will never sell your data. However, we may share or transfer some or all of your information with other entities, including but not limited to our affiliates, agents, third-party service providers, partners, banks, financial institutions to fulfill the purposes specified under this Policy, or as required under applicable law. You hereby authorise us to share and transfer your data. You acknowledge that some countries where we may transfer your information may not have data protection laws which are as stringent as the laws of your own country. You acknowledge that it is adequate that when Biotone transfers data to any other entity within or outside Your country of residence, Biotone will place contractual obligations on the transferee which will oblige the transferee to adhere to the provisions of this Privacy Policy.</span></p>
                <p><strong>ACCOUNT MODIFICATION</strong></p>
                <p><span>We use the information that you submit to us to create your Biotone profile.&nbsp; We recommend that you verify the accuracy and completeness of the information before submitting it to us. If you wish to make changes to your profile, please write to us at </span><a href="mailto:workwithus@biotone.com"><span>workwithus@biotone.com</span></a><span>, and provide supporting documentation. We reserve the right to decline your request to modify your details.</span></p>
                <p><strong>CHANGES TO PRIVACY POLICY</strong></p>
                <p><span>We may, at any time, make changes to this Privacy Policy with or without advance notice. In the event of a substantial change, we will notify you over email. However, we recommend that you check this page regularly. There is a tab at the end of this page which specifies when the Policy was last updated. If you object to any of the changes, please write to our Grievance Officer immediately to discuss a way forward.&nbsp;</span></p>
                <p><strong>COMPLIANCE WITH INDIAN LAW</strong></p>
                <p><span>This Privacy Policy is in compliance with the laws of India. You are not allowed to use the services or the Platform, if any of the terms are not in accordance with the applicable laws of your country.&nbsp;</span></p>
                <p><strong>GRIEVANCE OFFICER</strong></p>
                <p><span>We have appointed a Grievance Officer to address any concerns or grievances that You may have regarding the processing of Your Personal Information. If you have any such grievances, please write to our Grievance Officer, Pallavi Surana at </span><a href="mailto:talktous@biotone.com"><span>talktous@biotone.com</span></a><span>, and our officer will attempt to resolve Your issues in a timely manner.&nbsp;</span></p>
                <p>&nbsp;</p>
                <p><strong>DATE OF LAST REVISION: </strong><span>July 19, 2021</span></p>
            </div>
        </section>
        </div>
    );
}

export default PrivacyComponent;