import React from 'react';
import Logo from './../assets/images/illutrations/logo.svg';
import { Link } from "react-scroll";
// import Social1 from './../assets/images/illutrations/icons/social1.png';
// import Social2 from './../assets/images/illutrations/icons/social2.png';
// import Social3 from './../assets/images/illutrations/icons/social3.png';
// import Social4 from './../assets/images/illutrations/icons/social4.png';
// import Social1Hover from './../assets/images/illutrations/icons/social1_hover.png';
// import Social2Hover from './../assets/images/illutrations/icons/social2_hover.png';
// import Social3Hover from './../assets/images/illutrations/icons/social3_hover.png';
// import Social4Hover from './../assets/images/illutrations/icons/social4_hover.png';
function FooterComponent() {
    // const [over1, setOver1] = useState(false);
    // const [over2, setOver2] = useState(false);
    // const [over3, setOver3] = useState(false);
    // const [over4, setOver4] = useState(false);
    return (
        <footer className="footer-bs container">
                <div className="row">
                <div className="col-md-12 footer-brand text-center">
                    <a href="/">
                        <img className="img-fluid logo" src={Logo} alt="biotone_logo"/>
                    </a>
                    {/* <ul className="social-icons">
                        <li onMouseOver={() => setOver1(true)} onMouseOut={() => setOver1(false)}><a href="https://www.instagram.com/biotone/" target="_blank" rel="noreferrer"><img src={over1 ? Social1Hover : Social1 } alt="instagramlogo" className="img-fluid"/></a></li>
                        <li onMouseOver={() => setOver2(true)} onMouseOut={() => setOver2(false)}><a href="https://www.facebook.com/biotone" target="_blank" rel="noreferrer"><img src={over2 ? Social2Hover : Social2 } alt="facebooklogo" className="img-fluid"/></a></li>
                        <li onMouseOver={() => setOver3(true)} onMouseOut={() => setOver3(false)}><a href="https://www.linkedin.com/biotone" target="_blank" rel="noreferrer"><img src={over3 ? Social3Hover : Social3 } alt="linkedinlogo" className="img-fluid"/></a></li>
                        <li onMouseOver={() => setOver4(true)} onMouseOut={() => setOver4(false)}><a href="https://www.quora.com/biotone" target="_blank" rel="noreferrer"><img src={over4 ? Social4Hover : Social4 } alt="quoralogo" className="img-fluid"/></a></li>
                    </ul> */}
                    <ul className="links">
                        <li>
                            <Link
                            activeClass="active"
                            to="section1"
                            spy={true}
                            smooth={true}
                            offset={-100}
                            duration={500}
                            >
                            About
                            </Link> 
                        </li>
                        <li>
                            <Link
                            activeClass="active"
                            to="section2"
                            spy={true}
                            smooth={true}
                            offset={-100}
                            duration={500}
                            >
                            How it Works
                            </Link>
                        </li>
                        <li>
                        <Link
                            activeClass="active"
                            to="section4"
                            spy={true}
                            smooth={true}
                            offset={-100}
                            duration={500}
                            >
                            Contact Us
                        </Link>
                        </li>
                    </ul>
                </div>
            </div>
    </footer>
    );
}

export default FooterComponent;