import React, { useEffect }  from 'react';

function SmallCTAComponent(props) {
    useEffect(()=> {
    },[]);
    return (
            <div className="container mob-padding-zero">
            <section className="small-masthead text-center">
            <div className="masthead-content">
                <h3 className="masthead-text">“BioTone is the next step in the evolution of security for consumers and businesses.”</h3>
            </div>
            </section>
            </div>
    );
}

export default SmallCTAComponent;