import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Logo from './../assets/images/illutrations/logo.svg';
import { Link, animateScroll as scroll } from "react-scroll";

function HeaderComponent() {
  const scrollToTop = (event) => {
    scroll.scrollToTop();
  };
  return (
    <Navbar collapseOnSelect expand="lg" variant="light" id="mainNav">
      <div className="container">
        <span className="navbar-brand" onClick={scrollToTop}><img className="img-fluid" src={Logo} alt="minU_logo" /></span>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="mynavbar">
          <form className="d-flex">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link
                  className="nav-link"
                  activeClass="active"
                  to="section1"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  About Us
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  activeClass="active"
                  to="section2"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  How it Works
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="btn btn-primary"
                  activeClass="active"
                  to="section4"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </form>
        </div>
      </div>
    </Navbar>
  );
}

export default HeaderComponent;